import React from "react";
import { Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import styles from "./BugReason.module.scss";

interface Props {
  bugReason: Card["bugReason"];
}

const BugReason = ({ bugReason }: Props) =>
  bugReason ? (
    <Tooltip arrowPointAtCenter placement="topLeft" title={bugReason}>
      <WarningOutlined className={styles["warning"]} />
    </Tooltip>
  ) : null;

export default BugReason;
