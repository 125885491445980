import { Button, Form, Input, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import { RequireNewPassword } from "aws-amplify-react";
import React, { FormEvent } from "react";
import Helmet from "react-helmet";

import AuthLayout from "../layout/AuthLayout/AuthLayout";

export class CustomRequireNewPassword extends RequireNewPassword {
  _validAuthStates = ["requireNewPassword"];

  formRef = React.createRef<FormInstance>();

  inputs = {
    password: "",
  };

  handleSubmit = (e: FormEvent) => {
    e.persist();
    e.preventDefault();
    this.formRef
      .current!.validateFields()
      .then((values) => {
        super.change();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showComponent() {
    return (
      <AuthLayout>
        <Helmet>
          <title>Logowanie | Utylimed</title>
        </Helmet>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Utwórz nowe hasło
        </Typography.Title>
        <Form
          onSubmitCapture={this.handleSubmit}
          ref={this.formRef}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          hideRequiredMark
        >
          <Form.Item
            name="password"
            label="Hasło"
            rules={[
              {
                required: true,
                message: "To pole jest wymagane.",
              },
              {
                whitespace: true,
                message: "To pole jest wymagane.",
              },
              {
                pattern: /\d/g,
                message: "Hasło musi zawierać cyfrę",
              },

              {
                pattern: /.{8,}/g,
                message: "Hasło musi mieć co najmniej 8 znaków",
              },
              {
                pattern: /[A-ZŻŹĆĄŚĘŁÓŃ]/,
                message: "Hasło musi zawierać wielką literę",
              },
              {
                pattern: /[a-zżźćąśęłóń]/,
                message: "Hasło musi zawierać małą literę",
              },
            ]}
          >
            <Input.Password
              id="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Powtórz hasło"
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane.",
              },
              {
                validator: (rule, value) => {
                  return !value || value === this.inputs.password
                    ? Promise.resolve()
                    : Promise.reject("Hasła nie są takie same");
                },
              },
            ]}
          >
            <Input.Password
              id="confirm_password"
              key="confirm_password"
              name="confirm_password"
            />
          </Form.Item>
          <Button
            onClick={this.handleSubmit}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", height: 40, marginTop: 20 }}
          >
            Zaloguj
          </Button>
        </Form>
      </AuthLayout>
    );
  }
}
