import React from "react";
import { ClientsContextProvider } from "./ClientsContext";

interface Props {}

const ContextManager: React.FC<Props> = ({ children }) => {
  return <ClientsContextProvider>{children}</ClientsContextProvider>;
};

export default ContextManager;
