import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import useUserGroup from "hooks/useUserGroup";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import { RouteProps } from "react-router-dom";
import Page403 from "pages/403";

const AdminKlienci = React.lazy(() => import("./AdminKlienci/AdminKlienci"));

interface Props extends RouteProps {}

const routesDictionary: { [key in UserGroup]: JSX.Element } = {
  admin: <AdminKlienci />,
  storekeeper: <Page403 />,
};

export const KlienciPage: React.FC<Props> = () => {
  const userGroup = useUserGroup();

  return (
    <div>
      <Helmet>
        <title>Klienci | Utylimed</title>
      </Helmet>
      <Suspense fallback={<LoadingPlaceholder />}>
        {userGroup && routesDictionary[userGroup]}
      </Suspense>
    </div>
  );
};

export default KlienciPage;
