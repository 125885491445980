import axiosClient from "./axiosClient";
import { message } from "antd";

/**
 * Fetch route data
 * @param url
 * @param setDataCallback
 * @param [setLoadingCallback]
 */
export async function fetchRouteData(
  url: string,
  setDataCallback: React.Dispatch<React.SetStateAction<any>>,
  setLoadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoadingCallback && setLoadingCallback(true);
  try {
    const res = await axiosClient.get(url);
    setDataCallback(res.data);
    setLoadingCallback && setLoadingCallback(false);
    return res.data;
  } catch (err) {
    message.error("Wystąpił błąd, spróbuj później.");
    setLoadingCallback && setLoadingCallback(false);
  }
}

export default fetchRouteData;
