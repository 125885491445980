import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import useUserGroup from "hooks/useUserGroup";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import { RouteProps } from "react-router-dom";
import Page403 from "pages/403";

const AdminUzytkownicy = React.lazy(() =>
  import("./AdminUzytkownicy/AdminUzytkownicy")
);

interface Props extends RouteProps {}

const routesDictionary: { [key in UserGroup]: JSX.Element } = {
  admin: <AdminUzytkownicy />,
  storekeeper: <Page403 />,
};

export const UzytkownicyPage: React.FC<Props> = () => {
  const userGroup = useUserGroup();

  return (
    <div>
      <Helmet>
        <title>Użytkownicy | Utylimed</title>
      </Helmet>
      <Suspense fallback={<LoadingPlaceholder />}>
        {userGroup && routesDictionary[userGroup]}
      </Suspense>
    </div>
  );
};

export default UzytkownicyPage;
