import { NodeExpandOutlined } from "@ant-design/icons";
import { Alert, message, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import BugReason from "components/BugReason/BugReason";
import ChangeCardStatusButton from "components/ChangeCardStatusButton";
import EditCardButton from "components/EditCardButton";
import ModalButton from "components/ModalButton";
import PageHeading from "components/PageHeading/PageHeading";
import React, { useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { RouteProps } from "react-router-dom";
import fetchRouteData from "utils/fetchRouteData";
import { highlightMatches } from "utils/highlightMatches";
import parseVehicleRegNumber from "utils/parseVehicleRegNumber";

interface Props extends RouteProps {}

export const MagazynPage: React.FC<Props> = () => {
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRows, setSelectedRows] = useState<Card[]>([]);

  const highlightHandler = (value: any) => (
    <span style={{ whiteSpace: "pre-wrap", maxWidth: 400 }}>
      {highlightMatches(value, searchInput)}
    </span>
  );

  const fetchCards = useRef(() => {});

  const ChangeMultipleStatusesButton = () =>
    selectedRows.length > 0 ? (
      <ModalButton
        buttonProps={{
          icon: <NodeExpandOutlined />,
          type: "primary",
          style: { marginLeft: 10 },
        }}
        buttonTitle="Zmień statusy kart"
        method="POST"
        modalTitle="Zmiana statusów kart"
        endpoint="cards/changestatus"
        okText="Aktualizuj"
        parseValues={(values) => ({
          cards: selectedRows.map(({ card: fullCard, wasteMass }) => ({
            ...fullCard,
            wasteMass,
          })),
        })}
        tooltipText="Zmień statusy zaznaczonych w tabeli kart"
        successCallback={fetchCards.current}
      >
        {selectedRows.some(
          (c) => c.cardStatus === "Potwierdzenie przejęcia"
        ) && (
          <Alert
            style={{ marginBottom: 5 }}
            message={
              <>
                Wszystkie karty o statusie <b>Potwierdzenie przejęcia</b>{" "}
                zmienią status na <b>Potwierdzenie transportu</b>.
              </>
            }
            type="warning"
            showIcon
          />
        )}
        {selectedRows.some(
          (c) => c.cardStatus === "Potwierdzenie wygenerowane"
        ) && (
          <Alert
            style={{ marginBottom: 5 }}
            message={
              <>
                Wszystkie karty o statusie <b>Potwierdzenie wygenerowane</b>{" "}
                zmienią status na <b>Potwierdzenie przejęcia</b>.
              </>
            }
            type="warning"
            showIcon
          />
        )}
      </ModalButton>
    ) : null;

  const filteredData = useMemo(
    () =>
      searchInput
        ? cards.filter((item) =>
            Object.values(item).some((value) =>
              String(value).toLowerCase().includes(searchInput.toLowerCase())
            )
          )
        : cards,
    [cards, searchInput]
  );

  return (
    <div>
      <Helmet>
        <title>Magazyn | Utylimed</title>
      </Helmet>
      <PageHeading
        title="Magazyn – karty przejęte"
        onSearch={(e) => {
          e.persist();
          setSearchInput(e.currentTarget.value);
        }}
      >
        <Search
          placeholder="Numer rejestracyjny..."
          style={{ marginLeft: 10, maxWidth: 300 }}
          enterButton="Pobierz karty"
          onSearch={(value) => {
            const fetchData = () =>
              fetchRouteData(
                `storage/cards/receiver/${value}`,
                setCards,
                setLoading
              )
                .then((data: Card[]) => {
                  data.length > 0
                    ? message.success(`Liczba pobranych kart: ${data.length}`)
                    : message.warning("Nie znaleziono pasujących kart.");
                })
                .catch((err) => {
                  message.error(
                    "Coś poszło nie tak, spróbuj ponownie później."
                  );
                });

            fetchCards.current = fetchData;
            fetchData();
            setSelectedRows([]);
          }}
        />
        <ChangeMultipleStatusesButton />
      </PageHeading>
      <Table
        dataSource={filteredData}
        loading={loading}
        rowKey={(record) => record.card.cardNumber!}
        rowSelection={{
          onChange: (keys, rows) => {
            setSelectedRows(rows.filter((c) => !!c));
          },
        }}
        scroll={{ x: true }}
        columns={[
          {
            key: "bugReason",
            title: "Uwagi",
            align: "center",
            render: (value, item) => <BugReason bugReason={item.bugReason} />,
          },
          {
            dataIndex: "senderName",
            title: "Nazwa firmy",
            render: highlightHandler,
          },
          {
            dataIndex: "address",
            title: "Adres",
            render: highlightHandler,
          },
          {
            dataIndex: "wasteCode",
            title: "Numer odpadu",
            render: highlightHandler,
          },
          {
            dataIndex: "wasteMass",
            title: "Masa",
            render: highlightHandler,
          },
          {
            dataIndex: "cardStatus",
            title: "Kod",
            render: highlightHandler,
          },
          {
            dataIndex: "vehicleRegNumber",
            title: "Numer rejestracyjny",
            render: (value) =>
              highlightMatches(parseVehicleRegNumber(value), searchInput),
          },
          {
            dataIndex: "created_at",
            title: "Data utworzenia",
            sorter: (a, b) => a.created_at - b.created_at,
            defaultSortOrder: "descend",
            showSorterTooltip: false,
            render: (value) => new Date(value).toLocaleString("pl-PL"),
          },
          {
            dataIndex: "plannedTransportTime",
            sorter: (a, b) => {
              const dateA = new Date(a.plannedTransportTime);
              const dateB = new Date(b.plannedTransportTime);
              return dateA > dateB ? 1 : dateA === dateB ? 0 : -1;
            },
            title: "Planowana data",
            render: (value) => new Date(value).toLocaleString("pl-PL"),
          },
          {
            key: "akcje",
            title: "Akcje",
            fixed: "right",
            render: (value, item) => (
              <Row align="middle" justify="end" style={{ flexWrap: "nowrap" }}>
                <ChangeCardStatusButton card={item} isReceiver />
                <EditCardButton card={item} fetchCards={fetchCards.current} />
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
};

export default MagazynPage;
