import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import useUserGroup from "hooks/useUserGroup";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import { RouteProps, Redirect } from "react-router-dom";

const AdminHome = React.lazy(() => import("./AdminHome/AdminHome"));

interface Props extends RouteProps {}

const routesDictionary: { [key in UserGroup]: JSX.Element } = {
  admin: <AdminHome />,
  storekeeper: <Redirect to="/magazyn/wewnetrzne" />,
};

export const HomePage: React.FC<Props> = () => {
  const userGroup = useUserGroup();

  return (
    <div>
      <Helmet>
        <title>Strona główna | Utylimed</title>
      </Helmet>
      <Suspense fallback={<LoadingPlaceholder />}>
        {userGroup && routesDictionary[userGroup]}
      </Suspense>
    </div>
  );
};

export default HomePage;
