import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Select, Tooltip } from "antd";
import React, { useState } from "react";
import wasteCodes from "resources/wastecodes.json";

import ModalButton from "./ModalButton";

interface Props {
  card: Card;
  fetchCards: () => void;
}

const EditCardButton = ({ card: givenCard, fetchCards }: Props) => {
  const {
    eup_id,
    wasteCodeId,
    wasteMass,
    kpoId,
    vehicleRegNumber,
    cardStatusCodeName,
    wasteCode,
  } = givenCard.card;

  const [selectedWasteCode, setSelectedWasteCode] = useState<string>();

  return givenCard.needsUpdate ? (
    <Tooltip
      arrowPointAtCenter
      placement="topRight"
      title="Karta została zedytowana i wymaga odświeżenia strony"
    >
      <Button
        type="link"
        disabled
        style={{ marginLeft: 10 }}
        icon={<EditOutlined />}
      ></Button>
    </Tooltip>
  ) : (["APPROVED", "REJECTED"] as CardStatusCodename[]).some(
      (i) => i === cardStatusCodeName
    ) ? (
    <ModalButton<FullCard>
      method="PUT"
      modalTitle="Edycja karty"
      tooltipText="Edycja karty"
      initialValues={{ wasteCodeId, wasteMass }}
      buttonProps={{
        type: "link",
        style: { marginLeft: 10 },
        icon: <EditOutlined />,
      }}
      endpoint={`/cards/${eup_id}`}
      parseValues={(values) => ({
        cards: [
          {
            ...givenCard.card,
            ...values,
            vehicleRegNumber,
            kpoId,
            wasteCode: selectedWasteCode || wasteCode,
          },
        ],
      })}
      successMessage="Pomyślnie zedytowano kartę!"
      successCallback={fetchCards}
    >
      <Row gutter={20}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Numer odpadu"
            name="wasteCodeId"
            rules={[{ required: true, message: "To pole jest wymagane." }]}
            required
            hasFeedback
          >
            <Select
              showSearch
              optionFilterProp="key"
              placeholder="Wpisz numer odpadu"
              onSelect={(v, o) => {
                setSelectedWasteCode(o.key as string);
              }}
            >
              {wasteCodes.map(({ wasteCodeId, code }) => (
                <Select.Option key={code} value={wasteCodeId}>
                  {code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Masa odpadu"
            name="wasteMass"
            rules={[{ required: true, message: "To pole jest wymagane." }]}
            required
            hasFeedback
          >
            <InputNumber
              step={0.001}
              min={0.001}
              placeholder="Wpisz masę odpadu"
            />
          </Form.Item>
        </Col>
      </Row>
    </ModalButton>
  ) : (
    <Tooltip
      arrowPointAtCenter
      placement="topRight"
      title="Edytować można tylko zatwierdzone i odrzucone karty"
    >
      <Button
        type="link"
        disabled
        style={{ marginLeft: 10 }}
        icon={<EditOutlined />}
      ></Button>
    </Tooltip>
  );
};

export default EditCardButton;
