import React from "react";

import styles from "./AuthLayout.module.scss";

export const AuthLayout: React.FC = ({ children }) => (
  <div className={styles["auth-layout"]}>
    <div className={styles["auth-wrapper"]}>
      {/* <Typography.Title className="primary-text">Utylimed</Typography.Title> */}
      {children}
    </div>
  </div>
);

export default AuthLayout;
