import { VerifyContact } from "aws-amplify-react";
import React from "react";

import AuthLayout from "../layout/AuthLayout/AuthLayout";

export class CustomVerifyContact<Props> extends VerifyContact {
  _validAuthStates = ["verifyContact"];

  componentDidUpdate = () => {
    if (this.props.authState === "verifyContact") {
      this.changeState("signedIn");
    }
  };

  showComponent() {
    return <AuthLayout>test</AuthLayout>;
  }
}
