import React from "react";
import { Typography } from "antd";

export function highlightMatches(value: any, filter?: string) {
  return (
    <span>
      {filter
        ? String(value)
            .split(new RegExp(`(${filter})`, "gi"))
            .map((part, index) =>
              part.toLowerCase() === filter.toLowerCase() ? (
                <Typography.Text key={part + index} mark>
                  {part}
                </Typography.Text>
              ) : (
                part
              )
            )
        : value}
    </span>
  );
}
