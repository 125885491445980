import React from "react";
import styles from "./NavigatorManager.module.scss";

const NavigatorManager: React.FC = ({ children }) =>
  /MSIE|Trident/.test(window.navigator.userAgent) ? (
    <div className={styles["navigator-manager"]}>
      Niestety, nasza aplikacja nie wspiera Twojej przeglądarki. Zainstaluj
      nowszą przeglądarkę.
    </div>
  ) : (
    <>{children}</>
  );

export default NavigatorManager;
