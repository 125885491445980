import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";

/**
 * This hook provides access to currently logged user in CognitoUser
 * It listens to Hub "auth" event and updates returned value if user signs in or out
 * It returns null if promise has not yet resolved, false if user is not signed in and CognitoUser object is user is signed in
 * @returns user status
 */
function useUserStatus(): any | null {
  const [user, setUser] = useState<any | null>(null);

  useEffect(() => {
    const updateUser = () => {
      Auth.currentAuthenticatedUser()
        .then((user: any) => {
          setUser(user);
        })
        .catch((err) => {
          setUser(null);
        });
    };
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return user;
}

export default useUserStatus;
