import { Row, Table } from "antd";
import BugReason from "components/BugReason/BugReason";
import ChangeCardStatusButton from "components/ChangeCardStatusButton";
import EditCardButton from "components/EditCardButton";
import LoadingPlaceholder from "components/LoadingPlaceholder/LoadingPlaceholder";
import PageHeading from "components/PageHeading/PageHeading";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { RouteProps, useParams } from "react-router-dom";
import fetchRouteData from "utils/fetchRouteData";
import { highlightMatches } from "utils/highlightMatches";
import parseVehicleRegNumber from "utils/parseVehicleRegNumber";

interface Props extends RouteProps {}

export const MagazynPage: React.FC<Props> = () => {
  const params: {
    type: "wewnetrzne" | "zewnetrzne";
  } = useParams();

  const InnerComponent = ({ type }: { type: "wewnetrzne" | "zewnetrzne" }) => {
    const [cards, setCards] = useState<Card[]>([]);
    const [loading, setLoading] = useState(true);
    const cardTypeEN = {
      wewnetrzne: "internal",
      zewnetrzne: "external",
    }[type] as "internal" | "external";
    const [searchInput, setSearchInput] = useState("");
    const highlightHandler = (value: any) => (
      <span style={{ whiteSpace: "pre-wrap", maxWidth: 400 }}>
        {highlightMatches(value, searchInput)}
      </span>
    );

    const fetchCards = useCallback(() => {
      fetchRouteData("storage/cards/" + cardTypeEN, setCards, setLoading);
    }, []);

    useEffect(() => {
      fetchCards();
    }, []);

    const filteredData = useMemo(
      () =>
        searchInput
          ? cards.filter((item) =>
              Object.values(item).some((value) =>
                String(value).toLowerCase().includes(searchInput.toLowerCase())
              )
            )
          : cards,
      [cards, searchInput]
    );

    return loading ? (
      <LoadingPlaceholder />
    ) : (
      <div>
        <PageHeading
          title={
            "Magazyn – " +
            {
              wewnetrzne: "karty wewnętrzne",
              zewnetrzne: "karty zewnętrzne",
            }[type]
          }
          onSearch={(e) => {
            e.persist();
            setSearchInput(e.currentTarget.value);
          }}
        />
        <Table
          dataSource={filteredData}
          rowKey={(record) => record.card.cardNumber!}
          scroll={{ x: true }}
          columns={[
            {
              key: "bugReason",
              title: "Uwagi",
              align: "center",
              render: (value, item) => <BugReason bugReason={item.bugReason} />,
            },
            {
              dataIndex: "senderName",
              title: "Nazwa firmy",
              ellipsis: true,
              render: highlightHandler,
            },
            {
              dataIndex: "address",
              title: "Adres",
              ellipsis: true,
              render: highlightHandler,
            },
            {
              dataIndex: "wasteCode",
              title: "Numer odpadu",
              render: highlightHandler,
            },
            {
              dataIndex: "wasteMass",
              title: "Masa",
              render: highlightHandler,
            },
            {
              dataIndex: "cardStatus",
              title: "Kod",
              render: highlightHandler,
            },
            {
              dataIndex: "vehicleRegNumber",
              title: "Numer rejestracyjny",
              render: (value) =>
                highlightMatches(parseVehicleRegNumber(value), searchInput),
            },
            {
              dataIndex: "created_at",
              title: "Data utworzenia",
              sorter: (a, b) => a.created_at - b.created_at,
              defaultSortOrder: "descend",
              showSorterTooltip: false,
              render: (value) => new Date(value).toLocaleString("pl-PL"),
            },
            {
              dataIndex: "plannedTransportTime",
              sorter: (a, b) => {
                const dateA = new Date(a.plannedTransportTime);
                const dateB = new Date(b.plannedTransportTime);
                return dateA > dateB ? 1 : dateA === dateB ? 0 : -1;
              },
              title: "Planowana data",
              render: (value) => new Date(value).toLocaleString("pl-PL"),
            },
            {
              key: "akcje",
              title: "Akcje",
              fixed: "right",
              render: (value, item) => (
                <Row
                  align="middle"
                  justify="end"
                  style={{ flexWrap: "nowrap" }}
                >
                  <ChangeCardStatusButton card={item} />
                  <EditCardButton card={item} fetchCards={fetchCards} />
                </Row>
              ),
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>Magazyn | Utylimed</title>
      </Helmet>
      <InnerComponent type={params.type} />
    </div>
  );
};

export default MagazynPage;
