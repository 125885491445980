import { Auth, Hub } from "aws-amplify";
import React, { useEffect } from "react";
import axiosClient from "utils/axiosClient";

import type { HubCapsule } from "@aws-amplify/core/lib/Hub";

interface ClientsContextType {
  clients: Client[];
  setClients: (clients: Client[]) => void;
}

const initialContext: Client[] = [];

export const ClientsContext = React.createContext<ClientsContextType>({
  clients: initialContext,
  setClients: (clients: Client[]) => {
    console.log("Not loaded yet!");
  },
});

export const ClientsContextProvider: React.FC = ({ children }) => {
  const setClients = (clients: Client[]) => {
    setState((prev) => ({ ...prev, clients }));
  };

  const [state, setState] = React.useState<ClientsContextType>({
    clients: initialContext,
    setClients,
  });

  useEffect(() => {
    const updateEntities = async (capsule?: HubCapsule) => {
      if (capsule === undefined || capsule.payload.event === "signIn") {
        try {
          const res = await axiosClient.get("/clients");
          setClients(res.data);
        } catch (err) {
          setClients([]);
        }
      }
    };

    Auth.currentAuthenticatedUser().then((user) => {
      user && updateEntities();
    });

    Hub.listen("auth", updateEntities);
    return () => Hub.remove("auth", updateEntities);
  }, []);

  return (
    <ClientsContext.Provider value={state}>{children}</ClientsContext.Provider>
  );
};
