import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";

function useUserGroup(): UserGroup | null {
  const [currentUserGroup, setCurrentUserGroup] = useState<null | UserGroup>(
    null
  );

  useEffect(() => {
    const updateUser = () => {
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          const group: [
            UserGroup
          ] = user.getSignInUserSession()?.getAccessToken().payload[
            "cognito:groups"
          ];
          setCurrentUserGroup(...group);
        })
        .catch((err) => {
          setCurrentUserGroup(null);
        });
    };
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return currentUserGroup;
}

export default useUserGroup;
