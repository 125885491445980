import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

import styles from "./LoadingPlaceholder.module.scss";

interface Props {
  children?: never;
}

const LoadingPlaceholder: React.FC<Props> = () => (
  <div className={styles["loading"]}>
    <Spin size="large" indicator={<LoadingOutlined />} />
  </div>
);

export default LoadingPlaceholder;
