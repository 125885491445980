import { Button, Form, message, Modal, Tooltip } from "antd";
import { ButtonProps } from "antd/lib/button";
import { useForm } from "antd/lib/form/util";
import { ModalProps } from "antd/lib/modal";
import { AxiosError } from "axios";
import React, { useState } from "react";
import axiosClient from "utils/axiosClient";

type Props<O> = RequireAtLeastOne<
  {
    /**
     * Modal content - this should contain some kind of form
     */
    children: React.ReactNode;
    /**
     * Request method to be used
     */
    method: "POST" | "PUT";
    /**
     * Self explanatory
     */
    modalTitle: string;
    /**
     * Values that should populate the form fields
     */
    initialValues?: Partial<O>;
    /**
     * Self explanatory
     */
    buttonTitle?: string;
    /**
     * How button will be styled
     */
    buttonProps?: ButtonProps;
    /**
     * Modal confirm button text
     */
    okText?: string;
    /**
     * Modal confirm button props
     */
    okButtonProps?: ModalProps["okButtonProps"];
    /**
     * Endpoint that should request data
     */
    endpoint: string;
    /**
     * What to display on hover in tooltip
     */
    tooltipText?: string;
    /**
     * Function to fire on success. Either use this or `successMessage`
     */
    successCallback?: (responseData: any) => void;
    /**
     * What to display on success. Either use this or `successCallback`
     */
    successMessage?: string;
    /**
     * setState callback to fire when item is received
     */
    setDataCallback?: React.Dispatch<React.SetStateAction<O[]>>;
    /**
     * setState callback id prop to differentiate items
     */
    dataCallbackKey?: keyof O;
    parseValues?: (values: any) => { [key: string]: any };
    width?: number;
  },
  "buttonProps" | "buttonTitle"
>;

function ModalButton<O>({
  buttonProps,
  buttonTitle,
  children,
  endpoint,
  initialValues,
  method,
  modalTitle,
  okButtonProps,
  okText = "Dodaj",
  parseValues,
  setDataCallback,
  dataCallbackKey,
  successCallback,
  successMessage,
  tooltipText,
  width,
}: Props<O>) {
  const [modalIn, setModalIn] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = useForm();

  const onFinishHandle = (values: { [key: string]: any }) => {
    const valuesToSend = parseValues ? parseValues(values) : values;
    setModalLoading(true);
    method === "POST"
      ? axiosClient
          .post(endpoint, valuesToSend)
          .catch(errorCallback)
          .then(onSuccessHandler)
      : method === "PUT"
      ? axiosClient
          .put(endpoint, valuesToSend)
          .catch(errorCallback)
          .then(onSuccessHandler)
      : console.log("something went terribly wrong");
  };

  const onSuccessHandler = (res: any) => {
    if (setDataCallback) {
      method === "POST"
        ? setDataCallback((prev) => [res.data, ...prev])
        : method === "PUT"
        ? setDataCallback((prev) =>
            prev.map((item) =>
              item[dataCallbackKey!] === res.data.id
                ? { ...item, ...res.data }
                : item
            )
          )
        : console.log("something went terribly wrong");
    }

    successMessage && message.success(successMessage);
    successCallback && successCallback(res.data);

    setModalIn(false);
    setModalLoading(false);
    setTimeout(() => {
      form.resetFields();
    }, 300);
  };

  const errorCallback = (err: AxiosError<{ message: string }>) => {
    console.log(err);
    setModalLoading(false);
    message.error(err.response?.data.message);
  };

  return (
    <>
      <Modal
        visible={modalIn}
        width={width}
        confirmLoading={modalLoading}
        okText={okText}
        okButtonProps={okButtonProps}
        cancelText="Zamknij"
        onOk={(e) => {
          form.submit();
        }}
        onCancel={(e) => {
          setModalIn(false);
        }}
        title={modalTitle}
      >
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onFinishHandle}
          onKeyDown={(e) => e.keyCode === 13 && form.submit()}
          layout="vertical"
        >
          {children}
        </Form>
      </Modal>
      {tooltipText ? (
        <Tooltip title={tooltipText} placement="bottom">
          <Button {...buttonProps} onClick={(e) => setModalIn(true)}>
            {buttonTitle}
          </Button>
        </Tooltip>
      ) : (
        <Button {...buttonProps} onClick={(e) => setModalIn(true)}>
          {buttonTitle}
        </Button>
      )}
    </>
  );
}

export default ModalButton;
