import {
  AppstoreOutlined,
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
  PartitionOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Typography } from "antd";
import { Auth } from "aws-amplify";
import useUserGroup from "hooks/useUserGroup";
import useUserStatus from "hooks/useUserStatus";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import styles from "./AppLayout.module.scss";

interface Props {}

const AppLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const userData = useUserStatus();
  const userGroup = useUserGroup();

  const signOut = () => {
    Auth.signOut();
    history.push("/");
  };

  const AdminMenu = () => (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      defaultOpenKeys={["/uzytkownicy", "/magazyn"]}
    >
      <Menu.Item key="/">
        <Link to="/">
          <HomeOutlined className="nav-icon" />
          <span>Strona główna</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/klienci">
        <Link to="/klienci">
          <UserOutlined className="nav-icon" />
          <span>Klienci</span>
        </Link>
      </Menu.Item>
      <Menu.SubMenu
        key="/uzytkownicy"
        title={
          <>
            <TeamOutlined className="nav-icon" />
            <span>Użytkownicy</span>
          </>
        }
      >
        <Menu.Item key="/uzytkownicy/kierowcy">
          <Link to="/uzytkownicy/kierowcy">Kierowcy</Link>
        </Menu.Item>
        <Menu.Item key="/uzytkownicy/magazynierzy">
          <Link to="/uzytkownicy/magazynierzy">Magazynierzy</Link>
        </Menu.Item>
        <Menu.Item key="/uzytkownicy/administratorzy">
          <Link to="/uzytkownicy/administratorzy">Administratorzy</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="/magazyn"
        title={
          <>
            <AppstoreOutlined className="nav-icon" />
            <span>Magazyn</span>
          </>
        }
      >
        <Menu.Item key="/magazyn/wewnetrzne">
          <Link to="/magazyn/wewnetrzne">Wewnętrzne</Link>
        </Menu.Item>
        <Menu.Item key="/magazyn/zewnetrzne">
          <Link to="/magazyn/zewnetrzne">Zewnętrzne</Link>
        </Menu.Item>
        <Menu.Item key="/magazyn/przejete">
          <Link to="/magazyn/przejete">Przejęte</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="/opakowania">
        <Link to="/opakowania">
          <PartitionOutlined />
          <span>Opakowania</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const StorekeeperMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultOpenKeys={["/magazyn"]}
      >
        <Menu.SubMenu
          key="/magazyn"
          title={
            <>
              <AppstoreOutlined className="nav-icon" />
              <span>Magazyn</span>
            </>
          }
        >
          <Menu.Item key="/magazyn/wewnetrzne">
            <Link to="/magazyn/wewnetrzne">Wewnętrzne</Link>
          </Menu.Item>
          <Menu.Item key="/magazyn/zewnetrzne">
            <Link to="/magazyn/zewnetrzne">Zewnętrzne</Link>
          </Menu.Item>
          <Menu.Item key="/magazyn/przejete">
            <Link to="/magazyn/przejete">Przejęte</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  };

  return (
    userData && (
      <Layout>
        <Layout.Sider className={styles["sider"]} width={250}>
          <div className={styles["logo"]}>
            <Typography.Title level={2} style={{ color: "#fff", margin: 0 }}>
              Utylimed
            </Typography.Title>
          </div>
          {userGroup === "admin" ? <AdminMenu /> : <StorekeeperMenu />}
        </Layout.Sider>
        <Layout
          className={`${styles["layout"]} ${
            userGroup === "storekeeper" ? styles["storekeeper"] : ""
          }`}
        >
          <Layout.Header className={styles["site-header"]}>
            <Button
              style={{ marginRight: "auto" }}
              icon={<ClearOutlined />}
              onClick={() => window.location.reload(false)}
            >
              Odśwież
            </Button>
            Witaj,{" "}
            <span className="primary-text">{userData.attributes?.email}</span>
            <Button
              className={styles["logout-button"]}
              type="primary"
              onClick={signOut}
            >
              Wyloguj się
            </Button>
          </Layout.Header>
          <Layout.Content className={styles["main"]}>
            <div className={styles["background"]}>{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  );
};

export default AppLayout;
