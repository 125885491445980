import { Col, Input, Row, Typography } from "antd";
import React, { ChangeEventHandler, ReactNode } from "react";

import styles from "./PageHeading.module.scss";

interface Props {
  title: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  children?: ReactNode;
}

const PageHeading = ({ title, onSearch, children }: Props) => {
  return (
    <div className={styles["PageHeading"]} style={{ width: "100%" }}>
      <Row>
        <Col span={24} className={styles["page-heading"]}>
          <Typography.Title level={2} className={styles["page-heading-header"]}>
            {title}
          </Typography.Title>
          <div className={styles["inner-flex"]}>
            {onSearch && (
              <Input.Search
                onChange={onSearch}
                style={{ width: "auto" }}
                placeholder="Wyszukaj..."
              />
            )}
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageHeading;
