import {
  Authenticator,
  ForgotPassword,
  Greetings,
  RequireNewPassword,
  SignIn,
  VerifyContact,
} from "aws-amplify-react";
import React from "react";

import awsconfig from "../aws-config";
import { CustomRequireNewPassword, CustomSignIn } from "./";
import { CustomForgotPassword } from "./CustomForgotPassword";
import { CustomVerifyContact } from "./CustomVerifyContact";
import ParticlesBackground from "./ParticlesBackground";

interface Props {}

export const CustomAuthenticator: React.FC<Props> = (props) => (
  <Authenticator
    hide={[
      SignIn,
      Greetings,
      RequireNewPassword,
      ForgotPassword,
      VerifyContact,
    ]}
    amplifyConfig={awsconfig}
  >
    <CustomRequireNewPassword />
    <CustomSignIn />
    <CustomForgotPassword />
    <CustomVerifyContact />
    <ParticlesBackground />
    {props.children}
  </Authenticator>
);

export default CustomAuthenticator;
