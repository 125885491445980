import HomePage from "pages/Home/Home";
import KlienciPage from "pages/Klienci/Klienci";
import MagazynPage from "pages/Magazyn/Magazyn";
import MagazynPrzejetePage from "pages/Magazyn/Przejete/Przejete";
import UzytkownicyPage from "pages/Uzytkownicy/Uzytkownicy";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OpakowaniaPage from "pages/Opakowania/Opakowania";

export const routesConfig: {
  route: string;
  component: React.FC;
}[] = [
  {
    route: "/",
    component: HomePage,
  },
  {
    route: "/klienci",
    component: KlienciPage,
  },
  {
    route: "/uzytkownicy/:type",
    component: UzytkownicyPage,
  },
  {
    route: "/magazyn/przejete",
    component: MagazynPrzejetePage,
  },
  {
    route: "/magazyn/:type",
    component: MagazynPage,
  },
  {
    route: "/opakowania",
    component: OpakowaniaPage,
  },
];

export const Routes: React.FC = () => (
  <Switch>
    {routesConfig.map((item) => (
      <Route
        key={item.route}
        exact
        path={item.route}
        component={item.component}
      />
    ))}
    <Redirect to="/" />
  </Switch>
);

export default routesConfig;
