import { NodeExpandOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd";
import React, { useState } from "react";

import ModalButton from "./ModalButton";

interface Props {
  card: Card;
  isReceiver?: boolean;
}

interface FilterDataType {
  cardStatusCodeName: string;
}

const ChangeCardStatusButton = ({ card, isReceiver }: Props) => {
  const successCallback = (value: FullCard) => {
    window.location.reload();
  };

  const initialFilterData: FilterDataType = {
    cardStatusCodeName: "",
  };
  const [filterData, setFilterData] = useState<FilterDataType>(
    initialFilterData
  );

  return ([
    "RECEIVE_CONFIRMATION",
    "CONFIRMATION_GENERATED",
    "REJECTED",
  ] as CardStatusCodename[]).some((i) => i === card.card.cardStatusCodeName) ? (
    <ModalButton
      method="POST"
      modalTitle="Zmiana statusu karty"
      buttonProps={{
        type: "link",
        icon: <NodeExpandOutlined />,
      }}
      okText="Aktualizuj"
      parseValues={(values) => ({
        ...card.card,
        ...values,
        wasteMass: card.wasteMass,
      })}
      endpoint={`cards/${card.card.eup_id}/changestatus${
        isReceiver ? "?type=receiver" : ""
      }`}
      initialValues={
        card.card.cardStatusCodeName === "RECEIVE_CONFIRMATION"
          ? { status: "RECEIVE_CONFIRMATION_TRANSPORT_CONFIRMATION" }
          : undefined
      }
      successMessage="Pomyślnie zaktualizowano kartę!"
      tooltipText="Zmień status"
      successCallback={successCallback}
    >
      <Row gutter={20}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Możliwe akcje"
            name="status"
            rules={[{ required: true, message: "To pole jest wymagane" }]}
            required
            hasFeedback
          >
            {card.card.cardStatusCodeName === "CONFIRMATION_GENERATED" ? (
              <Select
                placeholder="Wybierz akcję"
                onChange={(value: string) => {
                  setFilterData(() => ({
                    cardStatusCodeName: value,
                  }));
                }}
              >
                <Select.Option value="CONFIRMATION_GENERATED_REJECTED">
                  Odrzuć kartę
                </Select.Option>
                <Select.Option value="CONFIRMATION_GENERATED_WITHDRAWN">
                  Wycofaj kartę
                </Select.Option>
                <Select.Option value="CONFIRMATION_GENERATED_RECEIVE_CONFIRMATION">
                  Potwierdź przejęcie
                </Select.Option>
              </Select>
            ) : card.card.cardStatusCodeName === "REJECTED" ? (
              <Select
                placeholder="Wybierz akcję"
                onChange={(value: string) => {
                  setFilterData(() => ({
                    cardStatusCodeName: value,
                  }));
                }}
              >
                <Select.Option value="CONFIRMATION_GENERATED_RECEIVE_CONFIRMATION">
                  Potwierdź przejęcie
                </Select.Option>
              </Select>
            ) : (
              <Select>
                <Select.Option value="RECEIVE_CONFIRMATION_TRANSPORT_CONFIRMATION">
                  Potwierdź transport
                </Select.Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        {(filterData.cardStatusCodeName === "CONFIRMATION_GENERATED_REJECTED" ||
          filterData.cardStatusCodeName ===
            "CONFIRMATION_GENERATED_WITHDRAWN") && (
          <Col xs={24} sm={12}>
            <Form.Item
              label={
                filterData.cardStatusCodeName ===
                "CONFIRMATION_GENERATED_REJECTED"
                  ? "Powód odrzucenia"
                  : "Powód wycofania"
              }
              name="remarks"
              rules={[{ required: true, message: "To pole jest wymagane." }]}
              required
              hasFeedback
            >
              <Input placeholder="Wpisz powód odrzucenia" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </ModalButton>
  ) : (
    <Tooltip
      arrowPointAtCenter
      placement="topRight"
      title="Karta została już zatwierdzona"
    >
      <Button type="link" disabled icon={<NodeExpandOutlined />}></Button>
    </Tooltip>
  );
};

export default ChangeCardStatusButton;
