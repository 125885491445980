import Axios from "axios";
import { awsendpoint } from "../aws-config";
import { Auth } from "aws-amplify";

export const axiosClient = Axios.create({
  baseURL: awsendpoint,
});

axiosClient.interceptors.request.use(function (config) {
  return Auth.currentSession()
    .then((session) => {
      // User is logged in. Set auth header on all requests
      config.headers.Authorization =
        "Bearer " + session.getIdToken().getJwtToken();
      return Promise.resolve(config);
    })
    .catch(() => {
      // No logged-in user: don't set auth header
      return Promise.resolve(config);
    });
});

export default axiosClient;
